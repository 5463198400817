<!-- 流水记录 -->

<template>
  <div class="record-page">
    <!-- <div class="status-tab">
      <div class="status-item active">
        <div class="word">流水记录</div>
      </div>
      <div class="status-item"><div class="word">兑换记录</div></div>
      <div class="status-item"><div class="word">提现记录</div></div>
    </div> -->
    <div class="status-tab">
      <div
        :class="orderStatus === item.value ? 'status-item active' : 'status-item'"
        v-for="item in statusOptions"
        :key="item.value"
        @click="changeStatus(item.value)"
      >
        <div class="word">{{ $i18n.t(item.name) }}</div>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <template slot="pulling">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loosing">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loading">
        {{ $i18n.t("rd_loading") }}
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="loadList"
      >
        <template slot="finished">
          {{ $i18n.t("rd_no_more") }}
        </template>
        <template slot="loading">
          {{ $i18n.t("rd_loading") }}
        </template>
        <div class="record-list">
          <div
            class="record-item"
            v-for="(item, index) in recordList[orderStatus - 1]"
            :key="index"
          >
            <div class="record-type-line">
              <div class="record-type">
                <span class="font-m">{{ $i18n.t(typeObj[item.type]) }} {{ item.coin}}</span>
                <div v-if="item.type === 'SWAP'" class="spical">
                  <!-- <span>{{ `${Math.abs(item.changedETH)}ETH` }}</span>
                  <span class="cus-text">≈</span> -->
                  <span>{{ `+${item.changedUSD}USDT` }}</span>
                </div>
              </div>
              <div class="current-num _self" :class="{'minus': (item.type === 'WITHDRAW' || item.currentNum < 0)}">{{ item.type !== "WITHDRAW" && item.currentNum > 0 ? `+${item.currentNum}` : `${item.type === "WITHDRAW" ? '-' : ''}${item.currentNum}` }} {{ item.type !== "SWAP" ? item.coin : "ETH" }}</div>
            </div>
            <!-- <div class="record-rate font-m" v-for="(item1, index1) in item.changes" :key="index1">
              <div>{{ item1.type }}</div>
              <div>
                {{ (item1.after - item1.before) | fixed_6 }}
                {{ item1.type.includes("USD") ? "USD" : "ETH" }}
              </div>
            </div> -->
            <div class="current-time-cus">
              <span>{{ utcDateSubtract(item.createdAt) }}</span>
              <span class="cus-status" v-if="item.type === 'WITHDRAW'">{{ formatText(item.status) }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import dayjs from 'dayjs';
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
export default {
  data() {
    return {
      statusLang: {
        applying: {
          "en": "Applying",
          "kr": "신청 중",
          "zh": "申请中",
          "th": "กำลังใช้…",
          "vi": "Applying",
          "ja": "申請中",
          "tr": "Başvuruluyor",
          "ru": "Запрос",
          "fr": "Applying"
        },
        pending: {
          "en": "Pending",
          "kr": "검토 중",
          "zh": "审核中",
          "th": "ที่รอดำเนินการ",
          "vi": "cấp phát",
          "ja": "審査中",
          "tr": "En attente",
          "ru": "На проверке",
          "fr": "Pending"
        },
        success: {
          "en": "Approve",
          "kr": "승인",
          "zh": "审核通过",
          "th": "อนุมัติ",
          "vi": "qua",
          "ja": "承認する",
          "tr": "Onayla",
          "ru": "с помощью",
          "fr": "Approve"
        },
        refuse: {
          "en": "Decline",
          "kr": "거부",
          "zh": "审核拒绝",
          "th": "ปฏิเสธ",
          "vi": "ปฏิเสธ",
          "ja": "拒否",
          "tr": "Reddet",
          "ru": "Отклонить",
          "fr": "Refuser"
        }
      },
      statusText: '',
      withdrawStatus: {
        PENDING: 'applying', // 申请中
        REVIEWING: 'pending', // 审核中
        PASSED: 'success', // 通过
        OVERRULED: 'refuse' // 拒绝
      },
      orderStatus: 1,
      statusOptions: [
        {
          name: "record_flow",
          value: 1,
        },
        {
          name: "record_exchange",
          value: 2,
        },
        {
          name: "record_withdrawal",
          value: 3,
        },
      ],
      typeObj: {
        // "CUSTOM": "系统发放", // cus-system
        // "WITHDRAW": "提现", // wal_withdrawal
        // "SWAP": "兑换", // wal_exchange
        // "INCOME": "结算收益", // cus-income-settlement
        // "INVITATION": "分红", // cus-db
        "CUSTOM": "cus_system", // cus_system
        "WITHDRAW": "wal_withdrawal", // wal_withdrawal
        "SWAP": "wal_exchange", // wal_exchange
        "INCOME": "cus_income_settlement", // cus_income_settlement
        "INVITATION": "cus_db", // cus_db
      },
      recordList: [],
      refreshing: false,
      loading: false,
      finished: true,
    };
  },
  filters: {
    fixed_6: function (value) {
      if (!value && value !== 0) return 0;
      return value.toFixed(6);
    },
  },
  computed: {
    finishedText() {
      return this.$i18n.t("record_nomore");
    },
    locale() {
      return this.$i18n.locale
    }
  },
  created() {
    this.getTransList();
  },
  methods: {
    formatText(status) {
      const cusObj = this.statusLang[this.withdrawStatus[status]]
      if (cusObj) {
        return cusObj[this.locale]
      } else {
        return ''
      }
    },
    substr61(val) {
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    utcDateSubtract(date) {
        if (typeof date !== 'string') return date
        date = `${date.replace(/\./g,"-").replace(' ', 'T')}Z`
        // console.log(date);
        return dayjs(date).format("YYYY-MM-DD HH:mm:ss")
        // return dayjs.utc(date) - dayjs.utc()
    },
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    changeStatus(status) {
      this.orderStatus = status;
    },
    onRefresh() {
      this.getTransList(1);
    },
    loadList() {},
    getTransList(type) {
      let that = this;
      if (this.$route.query.status && !type) {
        this.orderStatus = +this.$route.query.status;
      }
      this.$http
        .getTransList({
          page: 1,
          pageSize: 2000,
        })
        .then((res) => {
          if (res.code === 0) {
            const tmpList = res.data.list.filter(v => (v.changedETH !== 0 || v.changedUSD !== 0) && v.type !== "WITHDRAW");
            tmpList.forEach(v => {
              const tmp = v.changes[0]
              v.coin = tmp.type.includes("ETH") ? "ETH" : "USDT"
              // v.isMinus = tmp.before > tmp.after || v.type !== "SWAP"
              const [f, e] = `${tmp.after - tmp.before}`.split('.')
              const tmpx = e && e.length > 1 ? this.substr61(tmp.after - tmp.before) : f
              const tmpXx = tmpx.replace('-', '')
              v.currentNum = v.type !== "SWAP" ? tmpXx : this.substr61(v.changedETH)
            });
            // tmpList.sort((a, b) => +this.$moment(this.handleTime(b.createdAt)) - +this.$moment(this.handleTime(a.createdAt)))
            // 提现 WITHDRAW 兑换 SWAP 结算收益 INCOME 分红 INVITATION
            const swapList = tmpList.filter((v) => v.type === "SWAP");
            swapList.sort((a, b) => +this.$moment(this.handleTime(b.createdAt)) - +this.$moment(this.handleTime(a.createdAt)))
            // const withdrawList = tmpList.filter((v) => v.type === "WITHDRAW");
            // withdrawList.sort((a, b) => +this.$moment(this.handleTime(b.createdAt)) - +this.$moment(this.handleTime(a.createdAt)))
            this.$http.getUserWithdraws().then(wRes => {
              // console.log(wRes.data)
              const withdrawList = wRes.data ? wRes.data.map(v => {
                return {
                  ...v,
                  type: 'WITHDRAW',
                  coin: v.currency.includes("ETH") ? "ETH" : "USDT",
                  currentNum: v.amount
                }
              }) : []
              tmpList.push(...withdrawList)
              tmpList.sort((a, b) => +this.$moment(this.handleTime(b.createdAt)) - +this.$moment(this.handleTime(a.createdAt)))

              // const withdrawList = tmpList.filter((v) => v.type === "WITHDRAW");
              withdrawList.sort((a, b) => +this.$moment(this.handleTime(b.createdAt)) - +this.$moment(this.handleTime(a.createdAt)))

              console.log(withdrawList)
              that.recordList = [tmpList, swapList, withdrawList];
            })
            // console.log(withdrawList)
            // that.recordList = [tmpList, swapList, withdrawList];
          }
          this.finished = true;
          this.loading = false;
          this.refreshing = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>

.current-time-cus {
  text-align: left;
  line-height: 0.36rem;
  font-size: 12px;
  color: #999;
  display: flex;
  justify-content: space-between;
}
.record-page {
  padding-top: 56px;
  .status-tab {
    padding-bottom: 0;
  }
  .record-list {
    padding: 0 0.48rem;
  }
}
.record-type {
  word-break: break-all;
  text-align: left;
  display: flex;
  flex-direction: column;
  .spical {
    color: #00C9AB;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
  }
}
.current-num {
  font-size: 18px;
  color: #00C9AB;
  &.minus {
    color: #FD4C4C;
  }
  &._self {
    align-self: flex-end;
  }
}
</style>
